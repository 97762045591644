<template>
    <v-app>
        <PreorderNavbar :isSimia="isSimiaroom"></PreorderNavbar>
        <v-content>
            <v-container>
                <v-row justify="center" align="center" >
                    <v-col cols="12" sm="8" lg="6">
                        <v-card class="elevation-0">
                            <v-card-text>
                                <div class="mb-2 text-center" v-if="loading">{{message}}</div>

                                <v-progress-linear
                                        :active="loading"
                                        indeterminate
                                        query
                                        rounded
                                        stream
                                ></v-progress-linear>

                                <SessionCard
                                        :mode="session.mode"
                                        :session="session"
                                        :factor="false"
                                        :selectTime="false"
                                        v-if="session!=null"
                                ></SessionCard>
                                <!--<v-btn class="ma-5 blue white&#45;&#45;text" :disabled="loading || notValid" ref="myButton"-->
                                <!--:href="linkToSession" target="_blank">ورود به جلسه-->
                                <!--</v-btn>-->
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>


            </v-container>
            <PreOrderFooter :isSimiaroom="isSimiaroom"></PreOrderFooter>
        </v-content>

    </v-app>

</template>

<script>
    /*eslint-disable*/
    import PreorderNavbar from '@/components/PreorderNavbar.vue';
    import PreOrderFooter from '@/components/PreOrderFooter.vue';
    import SessionCard from '@/components/sessions/SessionCard.vue';

    export default {

        components: {
            PreorderNavbar,
            PreOrderFooter,
            SessionCard
        },
        mounted() {
            this.joinSession();
            if (window.origin.includes('simiaroom')) {
                this.isSimiaroom = true;
            } else {
                this.isSimiaroom = false;
            }
        },
        data() {
            return {
                session: null,
                linkToSession: '',
                code: this.$route.params.sessionCode,
                message: 'لطفا صبر کنید',
                notValid: null,
                loading: true,
                isSimiaroom: true
            }
        },
        methods: {
            clicked() {
                // this.$router.push('https://www.google.com');
            },
            joinSession() {
                this.$store.dispatch('joinSession', {code: this.code}).then((resp) => {
                    this.session = resp.data;
                    // this.linkToSession = resp.data.URL;

                    this.notValid = false;
                    // this.message = 'با کلیک بر روی دکمه زیر وارد جلسه شوید';
                    // window.location.href = resp.data.URL;

                }).catch((err) => {
                    this.notValid = true;
                    this.message = 'شما نمیتوانید وارد این جلسه شوید.'
                }).finally(() => {
                    this.loading = false;

                })
            }
        }

    }
</script>

<style scoped>

</style>